<template>
  <div class="search">
    <Header></Header>
    <div class="region">
      <div class="Basic_Nav">
        <div class="Name" v-text="data.Name"></div>
        <div class="School" v-text="data.School"></div>
        <div class="Category" v-text="data.Category"></div>
      </div>
      <div class="Detailed_Information">
        <div class="Img" v-bind:style="Img"></div>
        <div class="Link">主页链接：<a :href="data.Link" v-text="data.Link"></a></div>
        <div class="Age">性别：<span v-text="data.Age"></span></div>
        <div class="Mail">电子邮箱：<span v-text="data.Mail"></span></div>
        <div class="Time">最后更新时间：<span>暂无数据</span></div>
        <div class="Research">研究方向：<span v-text="Research_Text"></span><span class="In_Text" v-bind:style="In_Texts"
                                                                             @click="Open_Research">查看更多</span></div>
      </div>
      <div class="Paper_Nav">
        <div class="title">论文</div>
      </div>
      <div class="Paper_Information">

      </div>
      <div class="Prize_Nav">
        <div class="title">奖项</div>
      </div>
      <div class="Prize_Information">

      </div>
    </div>
    <div class="Research_main" v-bind:style="Research_Style">
      <div class="main">
        <div class="black" @click="close"></div>
        <div class="logo"></div>
        <div class="title">研究方向</div>
        <div class="text" v-text="data.Research"></div>
      </div>
    </div>
    <Footer class="Footer"></Footer>
  </div>
</template>

<script>
import Header from "@/components/pc/Header";
import Footer from "@/components/pc/Footer";

export default {
  mounted() {
    document.documentElement.scrollTop = 0;
    let _id = this.$route.query._id;
    if (_id) {
      this.$http.get(process.env.VUE_APP_URL + 'database/find/Precise', {
        params: {
          _id: _id
        }
      }).then(str => {
        let sum = str.data.results[0];
        if (sum) {
          this.data = sum;
          if (sum.Imgfile) {
            this.Img = 'background-image: url(' + process.env.VUE_APP_URL + 'image/' + sum.Imgfile + ');';
          }
          if (sum.Research.length > 100) {
            let text = sum.Research.slice(0, 100) + '.....';
            this.Research_Text = text;
            this.In_Texts = 'display:block;'
          } else {
            this.Research_Text = sum.Research;
          }
        } else {
          alert('服务端出错');
        }

      })
    }
  },
  data() {
    return {
      adver: '',
      data: [],
      Img: '',
      Research_Style:'',
      Research_Text: '',//临时研究方向数据
      In_Texts: ''
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {
    close(){
      this.Research_Style='display:none;'
    },
    Open_Research() {
      this.Research_Style='display:block;'
    },
    //预留该函数
    FX_Get_Img() {
      return;
    },
    close_adver() {
      this.adver = 'height:0'
    },
  }

}
</script>

<style scoped lang="scss">
.search {
  .advertisement {
    width: 100%;
    margin: 0 auto;
    height: 220px;
    background-color: #cacaca;
    position: relative;
    transition: 2s;
    overflow: hidden;
    margin-bottom: 20px;

    .block {
      position: absolute;
      background-image: url("../../../assets/image/ico/black.png");
      background-size: 100% 100%;
      width: 20px;
      height: 20px;
      right: 40px;
      top: 20px;
      cursor: pointer;
    }
  }

  @mixin Nav_block {
    width: 100%;
    height: 60px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 15px;
    color: white;
    line-height: 60px;
    text-indent: 20px;
    user-select: none;
    .title {
      @include block;
      font-size: 16px;
    }
  }
  @mixin block {
    display: inline-block;
  }

  .region {
    width: 1000px;
    min-height: 100px;
    margin: 0 auto;

    .Basic_Nav {
      @include Nav_block;
      background-color: #29294f;

      .Name {
        @include block;
        font-size: 19px;
      }

      .School {
        @include block;
        font-size: 11px;
        margin-left: 10px;
      }

      .Category {
        @include block;
        font-size: 7px;
        margin-left: 20px;
      }
    }

    .Detailed_Information {
      width: 100%;
      height: 330px;
      font-size: 16px;
      border-radius: 10px;
      margin-top: 15px;
      position: relative;
      user-select: none;
      box-shadow: #e4e7ed 0 0 10px 1px;

      .Img {
        position: absolute;
        width: 200px;
        height: 290px;
        left: 20px;
        top: 20px;
        background-color: #161f28;
        background-size: 100% 100%;
        border-radius: 8px;
      }

      @mixin Information_text {
        position: absolute;
        font-size: 15px;
        a {
          color: #409EFF;
          font-size: 14px;
          text-decoration: none;
        }
        span {
          font-size: 14px;
        }
      }

      .Link {
        @include Information_text;
        top: 25px;
        left: 240px;
      }

      .Age {
        @include Information_text;
        top: 80px;
        left: 240px;
      }

      .Mail {
        @include Information_text;
        top: 140px;
        left: 240px;
      }

      .Time {
        @include Information_text;
        bottom: 20px;
        right: 20px;
      }

      .Research {
        @include Information_text;
        width: 700px;
        height: 80px;
        top: 200px;
        left: 240px;

        .In_Text {
          color: #409EFF;
          font-size: 13px;
          cursor: pointer;
          display: none;
        }
      }
    }

    .Paper_Nav {
      @include Nav_block;
      background-color: #9e8545;
    }

    .Paper_Information {
      width: 100%;
      height: 330px;
      font-size: 16px;
      border-radius: 10px;
      margin-top: 15px;
      position: relative;
      user-select: none;
      box-shadow: #e4e7ed 0 0 10px 1px;
    }

    .Prize_Nav {
      @include Nav_block;
      background-color: #3048b2;
    }

    .Prize_Information {
      width: 100%;
      height: 330px;
      font-size: 16px;
      border-radius: 10px;
      margin-top: 15px;
      position: relative;
      user-select: none;
      box-shadow: #e4e7ed 0 0 10px 1px;
    }
  }

  .Research_main {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(0,0,0,0.5);
    display: none;
    .main{
      width: 700px;
      height: 500px;
      background-color: #ffffff;
      position: absolute;
      margin-left: -350px;
      margin-top: -250px;
      left: 50%;
      top: 50%;
      border-radius: 13px;
      .logo {
        background-image: url("../../../assets/image/home/logo.png");
        width: 80px;
        height: 30px;
        background-size: 100% 100%;
        position: absolute;
        left: 40px;
        top: 30px;
      }
      .black {
        position: absolute;
        background-image: url("../../../assets/image/ico/black.png");
        width: 18px;
        height: 18px;
        background-size: 100% 100%;
        right: 30px;
        top: 35px;
        cursor: pointer;
      }
      .title{
        position: absolute;
        left: 160px;
        top: 30px;
        height: 30px;
        line-height: 30px;

        span {
          width: 20px;
          height: 100%;
          display: inline-block;
          text-align: center;
          color: #cacaca;
        }
      }
      .text{
        position: absolute;
        top: 90px;
        left: 30px;
        height: 370px;
        width: 630px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 4px;
          background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #5f6368;
        }
      }

    }
  }

  .Footer {
    margin-top: 50px;
    position: initial;
    width: 100%;
    height: 40px;
  }
}
</style>
